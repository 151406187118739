'use client'

import { Card, Typography, Row, Col } from 'antd'
import { 
  Container,
  Link as LinkIcon,
  ClipboardCheck,
  ClipboardList
} from 'lucide-react'
import {Link} from  'react-router-dom'

const { Title } = Typography

export default function Component() {
  const menuItems = [
    {
      icon: <Container className="h-16 w-16" />,
      title: 'PSU Type Management',
      description: 'Manage physical service unit types and configurations',
      href: '/psu-type',
      className: 'col-span-full mb-8'
    },
    {
      icon: <LinkIcon className="h-16 w-16" style={{ color: '#1d4ed8' }} />,
      title: 'Connection',
      description: 'Manage network connections and routing',
      href: '/connection',
      className: 'md:col-span-1'
    },
    {
      icon: <ClipboardCheck className="h-16 w-16" style={{ color: '#60a5fa' }} />,
      title: 'Order',
      description: 'Process and track orders',
      href: '/orders',
      className: 'md:col-span-1'
    },
    {
      icon: <ClipboardList className="h-16 w-16" style={{ color: '#60a5fa' }} />,
      title: 'Shipment Order Management',
      description: 'Comprehensive shipment order tracking and management',
      href: '/shipment-management',
      className: 'col-span-full mt-8'
    }
  ]

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <div className="max-w-6xl mx-auto">
        <div className="text-center mb-12">
          <Title level={2}>Transport Layer</Title>
          <p className="text-lg text-muted-foreground mt-2">
            Manage all aspects of transport and shipping operations
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {menuItems.map((item, index) => (
            <Link 
              to={item.href}
              key={index} 
              onTouchEnd={() => window.location.href = item.href}
              className={`block no-underline ${item.className}`}
            >
              <Card 
                hoverable 
                className="h-full transition-all duration-300 hover:shadow-lg"
              >
                <div className="flex flex-col items-center text-center gap-4">
                  <div>
                    {item.icon}
                  </div>
                  <div>
                    <Title level={3} className="mb-2">
                      {item.title}
                    </Title>
                    <p className="text-muted-foreground text-lg">
                      {item.description}
                    </p>
                  </div>
                </div>
              </Card>
            </Link>
          ))}
        </div>

        {/* Quick Stats */}
        <Row gutter={[16, 16]} className="mt-8">
          <Col xs={24} sm={12} md={6}>
            <Card>
              <div className="text-center">
                <div className="text-lg font-semibold text-muted-foreground">Active PSUs</div>
                <div className="text-3xl font-bold text-primary mt-2">128</div>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Card>
              <div className="text-center">
                <div className="text-lg font-semibold text-muted-foreground">Connections</div>
                <div className="text-3xl font-bold text-primary mt-2">45</div>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Card>
              <div className="text-center">
                <div className="text-lg font-semibold text-muted-foreground">Active Orders</div>
                <div className="text-3xl font-bold text-primary mt-2">67</div>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Card>
              <div className="text-center">
                <div className="text-lg font-semibold text-muted-foreground">Shipments</div>
                <div className="text-3xl font-bold text-primary mt-2">89</div>
              </div>
            </Card>
          </Col>
        </Row>

        {/* System Status */}
        <Card className="mt-8">
          <div className="flex items-center justify-between">
            <div>
              <Title level={4} className="mb-0">System Status</Title>
              <p className="text-muted-foreground">All systems operational</p>
            </div>
            <div className="flex items-center gap-2">
              <span className="inline-block w-3 h-3 bg-green-500 rounded-full"></span>
              <span className="text-muted-foreground">Online</span>
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}