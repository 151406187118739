'use client'

import { useState } from 'react'
import { Button, Card, Form, Input, InputNumber, Modal, Radio, Select, Space, Table, Tag, Typography } from 'antd'
import { DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons'

const { Title } = Typography
const { Option } = Select

interface OrderItem {
  id: number
  type: string
  receivingHostName: string
  status: 'Pending' | 'Sending' | 'Received'
  createTime: string
  updateTime: string
}

interface PSUItem {
  psuId: number
  sendingPIP: string
  sendingPort: string
  receivingPIP: string
  receivingPort: string
  etaMin: string
  etaMax: string
  status: string
  nextHop: string
  carrier: string
}

interface Host {
  name: string
  telephoneNumber: string
  location: string
  port: string
  status: 'Available' | 'Unavailable'
}

interface RouteOption {
  key: string
  time: number
  cost: number
}

export default function Component() {
  const [form] = Form.useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isDetailVisible, setIsDetailVisible] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState<OrderItem | null>(null)
  const [selectedRoute, setSelectedRoute] = useState<string | null>(null)
  const [modalMode, setModalMode] = useState<'create' | 'edit'>('create')

  const hosts: Host[] = [
    {
      name: 'Host 1',
      telephoneNumber: '123-456-7890',
      location: 'Shanghai',
      port: '8080',
      status: 'Available',
    },
    {
      name: 'Host 2',
      telephoneNumber: '987-654-3210',
      location: 'Beijing',
      port: '8081',
      status: 'Unavailable',
    },
  ]

  const routeOptions: RouteOption[] = [
    { key: '1', time: 20, cost: 40 },
    { key: '2', time: 15, cost: 50 },
    { key: '3', time: 10, cost: 70 },
  ]

  const orderData: OrderItem[] = [
    {
      id: 1,
      type: 'Standard',
      receivingHostName: 'Shanghai DC',
      status: 'Pending',
      createTime: '2024-01-01 08:00',
      updateTime: '2024-01-01 08:00',
    },
    {
      id: 2,
      type: 'Express',
      receivingHostName: 'Beijing DC',
      status: 'Sending',
      createTime: '2024-01-02 09:00',
      updateTime: '2024-01-02 10:00',
    },
  ]

  const psuData: PSUItem[] = [
    {
      psuId: 1,
      sendingPIP: '192.168.1.100',
      sendingPort: '8080',
      receivingPIP: '192.168.2.200',
      receivingPort: '9090',
      etaMin: '2024-01-05 10:00',
      etaMax: '2024-01-05 14:00',
      status: 'Unpacked',
      nextHop: 'Shenzhen',
      carrier: 'CPI Express',
    },
  ]

  const orderColumns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Type', dataIndex: 'type', key: 'type' },
    { title: 'Receiving Host Name', dataIndex: 'receivingHostName', key: 'receivingHostName' },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <Tag color={
          status === 'Pending' ? 'gold' :
          status === 'Sending' ? 'blue' :
          'green'
        }>
          {status}
        </Tag>
      ),
    },
    { title: 'Create Time', dataIndex: 'createTime', key: 'createTime' },
    { title: 'Update Time', dataIndex: 'updateTime', key: 'updateTime' },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: OrderItem) => (
        <Space size="middle">
          <Button
            type="link"
            icon={<EyeOutlined />}
            onClick={() => {
              setSelectedOrder(record)
              setIsDetailVisible(true)
            }}
          >
            Detail
          </Button>
          {record.status === 'Pending' && (
            <>
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => {
                  setSelectedOrder(record)
                  setModalMode('edit')
                  form.setFieldsValue(record)
                  setIsModalVisible(true)
                }}
              >
                Edit
              </Button>
              <Button
                type="link"
                danger
                icon={<DeleteOutlined />}
                onClick={() => {
                  setSelectedOrder(record)
                  setIsDeleteModalVisible(true)
                }}
              >
                Delete
              </Button>
            </>
          )}
        </Space>
      ),
    },
  ]

  const psuColumns = [
    { title: 'PSU ID', dataIndex: 'psuId', key: 'psuId' },
    { title: 'Sending PIP', dataIndex: 'sendingPIP', key: 'sendingPIP' },
    { title: 'Sending Port', dataIndex: 'sendingPort', key: 'sendingPort' },
    { title: 'Receiving PIP', dataIndex: 'receivingPIP', key: 'receivingPIP' },
    { title: 'Receiving Port', dataIndex: 'receivingPort', key: 'receivingPort' },
    { title: 'ETA Min', dataIndex: 'etaMin', key: 'etaMin' },
    { title: 'ETA Max', dataIndex: 'etaMax', key: 'etaMax' },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <Tag color={
          status === 'Unpacked' ? 'default' :
          status === 'Awaiting Pickup' ? 'gold' :
          status === 'Ship in Progress' ? 'blue' :
          status === 'In Transit' ? 'purple' :
          'green'
        }>
          {status}
        </Tag>
      ),
    },
    { title: 'Next Hop', dataIndex: 'nextHop', key: 'nextHop' },
    { title: 'Carrier', dataIndex: 'carrier', key: 'carrier' },
    {
      title: 'Action',
      key: 'action',
      render: () => (
        <Space size="middle">
          <Button type="link">Item Details</Button>
          <Button type="link">Monitor Shipment Status</Button>
        </Space>
      ),
    },
  ]

  const handleCreateOrder = (values: any) => {
    console.log('Create order:', values)
    setIsModalVisible(false)
    form.resetFields()
  }

  const handleDeleteOrder = () => {
    console.log('Delete order:', selectedOrder?.id)
    setIsDeleteModalVisible(false)
  }

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <Title level={2}>Create Customer Order</Title>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setModalMode('create')
            form.resetFields()
            setIsModalVisible(true)
          }}
        >
          Create Order
        </Button>
      </div>

      <Card className="mb-6">
        <Table
          columns={orderColumns}
          dataSource={orderData}
          rowKey="id"
        />
      </Card>

      {/* Create/Edit Order Modal */}
      <Modal
        title={modalMode === 'create' ? 'Create Order' : 'Edit Order'}
        open={isModalVisible}
        onOk={form.submit}
        onCancel={() => setIsModalVisible(false)}
        width={1000}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleCreateOrder}
        >
          <Card title="Host Selection" className="mb-4">
            <div className="grid grid-cols-2 gap-4">
              <Form.Item label="Sending Host" name="sendingHost" rules={[{ required: true }]}>
                <Select>
                  {hosts.map(host => (
                    <Option key={host.name} value={host.name}>
                      {host.name} - {host.location}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label="Receiving Host" name="receivingHost" rules={[{ required: true }]}>
                <Select>
                  {hosts.map(host => (
                    <Option 
                      key={host.name} 
                      value={host.name}
                      disabled={host.status === 'Unavailable'}
                    >
                      {host.name} - {host.location}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Card>

          <Card title="Item Information" className="mb-4">
            <div className="grid grid-cols-2 gap-4">
              <Form.Item label="Name" name="itemName" rules={[{ required: true }]}>
                <Input />
              </Form.Item>

              <Form.Item label="Type" name="itemType" rules={[{ required: true }]}>
                <Input />
              </Form.Item>

              <Form.Item label="Location" name="itemLocation" rules={[{ required: true }]}>
                <Input />
              </Form.Item>

              <Form.Item label="Weight (kg)" name="itemWeight" rules={[{ required: true }]}>
                <InputNumber className="w-full" />
              </Form.Item>

              <Form.Item label="Size" name="itemSize" rules={[{ required: true }]}>
                <Input placeholder="Length x Width x Height" />
              </Form.Item>

              <Form.Item label="Shipment Condition" name="shipmentCondition" rules={[{ required: true }]}>
                <Select>
                  <Option value="normal">Normal</Option>
                  <Option value="fragile">Fragile</Option>
                  <Option value="sensitive">Temperature Sensitive</Option>
                </Select>
              </Form.Item>

              <Form.Item label="ETA Min" name="etaMin" rules={[{ required: true }]}>
                <Input type="datetime-local" />
              </Form.Item>

              <Form.Item label="ETA Max" name="etaMax" rules={[{ required: true }]}>
                <Input type="datetime-local" />
              </Form.Item>
            </div>
          </Card>

          <Card title="Route Options">
            <Table
              dataSource={routeOptions}
              pagination={false}
              columns={[
                { title: 'Option', dataIndex: 'key', render: (key) => `Option ${key}` },
                { title: 'Time (hours)', dataIndex: 'time' },
                { title: 'Cost ($)', dataIndex: 'cost' },
                {
                  title: 'Select',
                  render: (_, record) => (
                    <Radio
                      checked={selectedRoute === record.key}
                      onChange={() => {
                        setSelectedRoute(record.key)
                        form.setFieldsValue({ routeOption: record.key })
                      }}
                    />
                  ),
                },
              ]}
            />
            <Form.Item name="routeOption" hidden>
              <Input />
            </Form.Item>

            <div className="mt-4 bg-gray-100 p-4 rounded-lg">
              <div className="aspect-video relative">
                <div className="absolute inset-0 flex items-center justify-center text-gray-500">
                  Route visualization would be displayed here
                </div>
              </div>
            </div>
          </Card>
        </Form>
      </Modal>

      {/* Order Details Modal */}
      <Modal
        title="Order Details"
        open={isDetailVisible}
        footer={null}
        onCancel={() => setIsDetailVisible(false)}
        width={1000}
      >
        <Card title="PSU Information" className="mb-4">
          <Table
            columns={psuColumns}
            dataSource={psuData}
            pagination={false}
          />
        </Card>

        <Card title="Item Details" className="mb-4">
          <Table
            columns={[
              { title: 'Item Name', dataIndex: 'itemName' },
              { title: 'Number of Items', dataIndex: 'quantity' },
            ]}
            dataSource={[{ itemName: 'Sample Item', quantity: 1 }]}
            pagination={false}
          />
        </Card>

        <Card title="Shipping Route">
          <div className="h-[400px] bg-gray-100 rounded-lg flex items-center justify-center">
            <p className="text-gray-500">Shipping route visualization would be displayed here</p>
          </div>
        </Card>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        title="Delete Order"
        open={isDeleteModalVisible}
        onOk={handleDeleteOrder}
        onCancel={() => setIsDeleteModalVisible(false)}
      >
        <p>Choose OK to confirm the delete or CANCEL to back out of the del</p>
      </Modal>
    </div>
  )
}