import React from 'react';
import { Layout, Card, Typography, Row, Col } from 'antd';
import {
  CloudServerOutlined,
  NodeIndexOutlined,
  DatabaseOutlined,
  CarOutlined,
  UserOutlined,
} from '@ant-design/icons';

const { Content } = Layout;
const { Title } = Typography;

const menuItems = [
  {
    title: 'Sending Host',
    icon: <CloudServerOutlined style={{ fontSize: 48, color: '#69c0ff' }} />,
    key: 'sending-host',
  },
  {
    title: 'Router',
    icon: <NodeIndexOutlined style={{ fontSize: 48, color: '#597ef7' }} />,
    key: 'router',
  },
  {
    title: 'Receiving Host',
    icon: <DatabaseOutlined style={{ fontSize: 48, color: '#1890ff' }} />,
    key: 'receiving-host',
  },
  {
    title: 'Carrier',
    icon: <CarOutlined style={{ fontSize: 48, color: '#36cfc9' }} />,
    key: 'carrier',
  },
  {
    title: 'CPISP',
    icon: <UserOutlined style={{ fontSize: 48, color: '#40a9ff' }} />,
    key: 'cpisp',
  },
];

export default function MainPage() {
  return (
    <Layout>
      <Content style={{ padding: '24px', minHeight: '100vh' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px', marginBottom: '48px' }}>
          {/* <img src="/placeholder.svg?height=48&width=48" alt="CPI Logo" style={{ width: '48px', height: '48px' }} /> */}
          <Title level={2} style={{ margin: 0 }}>
            Cyber Physical Internet
          </Title>
        </div>
        
        <Row gutter={[24, 24]} justify="center">
          {menuItems.map((item) => (
            <Col xs={24} sm={12} md={8} key={item.key}>
              <Card
                hoverable
                style={{
                  textAlign: 'center',
                  cursor: 'pointer',
                  transition: 'all 0.3s',
                }}
                bodyStyle={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '16px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '80px',
                    height: '80px',
                    borderRadius: '50%',
                    background: '#f0f5ff',
                    marginBottom: '16px',
                  }}
                >
                  {item.icon}
                </div>
                <Title level={4} style={{ margin: 0 }}>
                  {item.title}
                </Title>
              </Card>
            </Col>
          ))}
        </Row>
      </Content>
    </Layout>
  );
}