'use client'

import { Card, Typography } from 'antd'
import { 
  Activity,
  Database,
  FileSpreadsheet,
  Settings,
  Truck
} from 'lucide-react'
import { Link } from 'react-router-dom'

const { Title } = Typography

export default function Component() {
  const menuItems = [
    {
      icon: <Activity className="h-12 w-12" />,
      title: 'Bandwidth',
      description: 'Monitor and manage network bandwidth',
      href: '/bandwidth'
    },
    {
      icon: <Database className="h-12 w-12" />,
      title: 'Buffer Status',
      description: 'View buffer allocation and status',
      href: '/buffer'
    },
    {
      icon: <FileSpreadsheet className="h-12 w-12" />,
      title: 'Routing Table',
      description: 'Configure and view routing information',
      href: '/routing'
    },
    {
      icon: <Settings className="h-12 w-12" />,
      title: 'Network Configuration',
      description: 'Manage network settings and parameters',
      href: '/config'
    },
    {
      icon: <Truck className="h-12 w-12" />,
      title: 'Logistic State Advertisement',
      description: 'Monitor logistics and delivery status',
      href: '/logistics'
    }
  ]

  return (
    <div className="p-6">
      <div className="text-center mb-8">
        <Title level={2}>Router Manager</Title>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {menuItems.map((item, index) => (
          <Link 
            key={index} 
            to={item.href}
            className="block no-underline"
          >
            <Card 
              hoverable 
              className="h-full transition-all duration-300 hover:shadow-lg"
            >
              <div className="flex flex-col items-center text-center gap-4">
                <div className="text-primary">
                  {item.icon}
                </div>
                <div>
                  <Title level={4} className="mb-2">
                    {item.title}
                  </Title>
                  <p className="text-muted-foreground">
                    {item.description}
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        ))}
      </div>
    </div>
  )
}