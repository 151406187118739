'use client'

import { useState } from 'react'
import { Button, Card, Form, Input, Typography, message } from 'antd'
import { EyeInvisibleOutlined, EyeOutlined, UserOutlined, LockOutlined, SafetyCertificateOutlined } from '@ant-design/icons'
import Logo from './logo'

const { Title } = Typography

interface LoginForm {
  username: string
  password: string
  verificationCode: string
}

export default function Component() {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const onFinish = async (values: LoginForm) => {
    try {
      setLoading(true)
      console.log('Login attempt:', values)
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000))
      message.success('Login successful')
    } catch (error) {
      message.error('Login failed')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="min-h-screen bg-slate-900 flex items-center justify-center p-4">
      <Card 
        className="w-full max-w-md bg-slate-800 shadow-xl"
        bordered={false}
      >
        <div className="text-center mb-8">
          <Title level={2} className="text-white m-0">
            Welcome to the system
          </Title>
        </div>

        <Form
          form={form}
          name="login"
          onFinish={onFinish}
          layout="vertical"
          requiredMark={false}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input
              prefix={<UserOutlined className="text-slate-400" />}
              placeholder="Username"
              size="large"
              className="bg-slate-700 border-slate-600 text-white"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password
              prefix={<LockOutlined className="text-slate-400" />}
              placeholder="Password"
              size="large"
              className="bg-slate-700 border-slate-600 text-white"
              iconRender={visible => (
                visible ? 
                <EyeOutlined className="text-slate-400" /> : 
                <EyeInvisibleOutlined className="text-slate-400" />
              )}
            />
          </Form.Item>

          <Form.Item
            name="verificationCode"
            rules={[{ required: true, message: 'Please input verification code!' }]}
          >
            <div className="flex gap-4">
              <Input
                prefix={<SafetyCertificateOutlined className="text-slate-400" />}
                placeholder="Verification Code"
                size="large"
                className="bg-slate-700 border-slate-600 text-white"
              />
              <div className="flex-shrink-0 w-24 h-10 bg-slate-700 rounded flex items-center justify-center">
                <Logo/>
              </div>
            </div>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={loading}
              className="w-full bg-blue-600 hover:bg-blue-500"
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  )
}