import { Card, Typography } from 'antd'
import { DatabaseOutlined, FileSearchOutlined, HomeOutlined, NodeIndexOutlined, UserOutlined } from '@ant-design/icons'

const { Title, Text } = Typography

export default function Component() {
  const modules = [
    {
      title: 'Customer Order Management',
      icon: <UserOutlined className="text-4xl" />,
    },
    {
      title: 'CPI Host Management',
      icon: <DatabaseOutlined className="text-4xl" />,
    },
    {
      title: 'Warehouse Management',
      icon: <HomeOutlined className="text-4xl" />,
    },
    {
      title: 'Bandwidth',
      icon: <NodeIndexOutlined className="text-4xl" />,
    },
    {
      title: 'Routing Table',
      icon: <FileSearchOutlined className="text-4xl" />,
    },
  ]

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Main Content */}
      <div className="max-w-7xl mx-auto p-4">
        <div className="mb-8">
          <Title level={2}>Sending Host</Title>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {modules.map((module, index) => (
            <Card
              key={index}
              hoverable
              className="text-center cursor-pointer transition-shadow hover:shadow-md"
            >
              <div className="flex flex-col items-center gap-4 py-6">
                {module.icon}
                <Text strong className="text-lg">
                  {module.title}
                </Text>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </div>
  )
}