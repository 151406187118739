'use client'

import { useState } from 'react'
import { 
  Button, 
  Card, 
  Form, 
  Input, 
  Modal, 
  Space, 
  Table, 
  Typography,
  message 
} from 'antd'
import { EditOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons'

const { Title } = Typography

interface Connection {
  key: string
  type: string
  hostName: string
  destinationPIP: string
  port: string
  creationTime: string
  speed: string
  sent: string
  received: string
  total: string
  orderDetails?: {
    orderId: string
    status: string
    items: number
    priority: string
  }
}

export default function Component() {
  const [form] = Form.useForm()
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [isViewModalVisible, setIsViewModalVisible] = useState(false)
  const [selectedConnection, setSelectedConnection] = useState<Connection | null>(null)
  const [searchText, setSearchText] = useState('')

  const data: Connection[] = [
    {
      key: '1',
      type: 'TCP',
      hostName: 'Shanghai-DC-01',
      destinationPIP: '192.168.1.100',
      port: '8080',
      creationTime: '2024-01-01 08:00:00',
      speed: '1 Gbps',
      sent: '1.5 GB',
      received: '2.3 GB',
      total: '3.8 GB',
      orderDetails: {
        orderId: 'ORD-001',
        status: 'Active',
        items: 5,
        priority: 'High'
      }
    },
    {
      key: '2',
      type: 'UDP',
      hostName: 'Beijing-DC-02',
      destinationPIP: '192.168.1.101',
      port: '8081',
      creationTime: '2024-01-01 09:00:00',
      speed: '2 Gbps',
      sent: '2.1 GB',
      received: '1.8 GB',
      total: '3.9 GB',
      orderDetails: {
        orderId: 'ORD-002',
        status: 'Active',
        items: 3,
        priority: 'Medium'
      }
    },
  ]

  const filteredData = data.filter(item =>
    Object.values(item).some(val => 
      val && typeof val === 'string' && 
      val.toLowerCase().includes(searchText.toLowerCase())
    )
  )

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Host Name',
      dataIndex: 'hostName',
      key: 'hostName',
    },
    {
      title: 'Destination PIP',
      dataIndex: 'destinationPIP',
      key: 'destinationPIP',
    },
    {
      title: 'Port',
      dataIndex: 'port',
      key: 'port',
    },
    {
      title: 'Creation Time',
      dataIndex: 'creationTime',
      key: 'creationTime',
    },
    {
      title: 'Speed',
      dataIndex: 'speed',
      key: 'speed',
    },
    {
      title: 'Sent',
      dataIndex: 'sent',
      key: 'sent',
    },
    {
      title: 'Received',
      dataIndex: 'received',
      key: 'received',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: 'Order Detail',
      key: 'orderDetail',
      render: (_: any, record: Connection) => (
        <Button
          type="link"
          icon={<EyeOutlined />}
          onClick={() => {
            setSelectedConnection(record)
            setIsViewModalVisible(true)
          }}
        >
          View
        </Button>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: Connection) => (
        <Button
          type="link"
          icon={<EditOutlined />}
          onClick={() => {
            setSelectedConnection(record)
            form.setFieldsValue(record)
            setIsEditModalVisible(true)
          }}
        >
          Edit
        </Button>
      ),
    },
  ]

  const handleEdit = (values: any) => {
    console.log('Edit connection:', values)
    message.success('Connection updated successfully')
    setIsEditModalVisible(false)
  }

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <Title level={2}>Connection Management</Title>
      </div>

      <div className="mb-4">
        <Input
          placeholder="Filter connections..."
          prefix={<SearchOutlined />}
          onChange={e => setSearchText(e.target.value)}
          className="max-w-md"
          allowClear
        />
      </div>

      <Card>
        <Table
          columns={columns}
          dataSource={filteredData}
          pagination={{ pageSize: 10 }}
          scroll={{ x: true }}
        />
      </Card>

      {/* Edit Connection Modal */}
      <Modal
        title="Edit Connection"
        open={isEditModalVisible}
        onOk={form.submit}
        onCancel={() => setIsEditModalVisible(false)}
        width={800}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleEdit}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Form.Item 
              label="Type" 
              name="type" 
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item 
              label="Host Name" 
              name="hostName" 
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item 
              label="Destination PIP" 
              name="destinationPIP" 
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item 
              label="Port" 
              name="port" 
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item 
              label="Speed" 
              name="speed" 
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </div>
        </Form>
      </Modal>

      {/* View Order Details Modal */}
      <Modal
        title="Order Details"
        open={isViewModalVisible}
        footer={null}
        onCancel={() => setIsViewModalVisible(false)}
      >
        {selectedConnection?.orderDetails && (
          <div className="space-y-4">
            <div>
              <div className="font-semibold">Order ID</div>
              <div>{selectedConnection.orderDetails.orderId}</div>
            </div>
            <div>
              <div className="font-semibold">Status</div>
              <div>{selectedConnection.orderDetails.status}</div>
            </div>
            <div>
              <div className="font-semibold">Number of Items</div>
              <div>{selectedConnection.orderDetails.items}</div>
            </div>
            <div>
              <div className="font-semibold">Priority</div>
              <div>{selectedConnection.orderDetails.priority}</div>
            </div>
            <div>
              <div className="font-semibold">Connection Details</div>
              <div className="space-y-2">
                <div>Type: {selectedConnection.type}</div>
                <div>Host: {selectedConnection.hostName}</div>
                <div>Destination: {selectedConnection.destinationPIP}</div>
                <div>Port: {selectedConnection.port}</div>
                <div>Speed: {selectedConnection.speed}</div>
                <div>Data Transfer:</div>
                <div className="pl-4">
                  <div>Sent: {selectedConnection.sent}</div>
                  <div>Received: {selectedConnection.received}</div>
                  <div>Total: {selectedConnection.total}</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  )
}