'use client'

import { Badge, Card, Table, Typography, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useState } from 'react'

const { Title } = Typography

interface NetworkMetric {
  key: string
  destinationPIP: string
  type: string
  mask: string
  h5Status: 'success' | 'error' | 'default'
  w30Status: 'success' | 'error' | 'default'
  dgpStatus: 'success' | 'error' | 'default'
  t24Status: 'success' | 'error' | 'default'
  time: number
  cost: number
  defaultSetting: string
  nextHop: string
  ping: 'success' | 'error'
}

export default function Component() {
  const columns = [
    {
      title: 'Destination PIP',
      dataIndex: 'destinationPIP',
      key: 'destinationPIP',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Mask',
      dataIndex: 'mask',
      key: 'mask',
    },
    {
      title: (
        <span>
          Condition Metric{' '}
          <Tooltip title="Network condition metrics">
            <QuestionCircleOutlined />
          </Tooltip>
        </span>
      ),
      children: [
        {
          title: 'H-5',
          dataIndex: 'h5Status',
          key: 'h5Status',
          render: (status: "success" | "error" | "default" | "processing" | "warning" | undefined) => <Badge status={status} />,
        },
        {
          title: 'W-30',
          dataIndex: 'w30Status',
          key: 'w30Status',
          render: (status: "success" | "error" | "default" | "processing" | "warning" | undefined) => <Badge status={status} />,
        },
        {
          title: 'DGP',
          dataIndex: 'dgpStatus',
          key: 'dgpStatus',
          render: (status: "success" | "error" | "default" | "processing" | "warning" | undefined) => <Badge status={status} />,
        },
        {
          title: 'T-24',
          dataIndex: 't24Status',
          key: 't24Status',
          render: (status: "success" | "error" | "default" | "processing" | "warning" | undefined) => <Badge status={status} />,
        },
      ],
    },
    {
      title: 'Numerical Metric',
      children: [
        {
          title: 'Time (ms)',
          dataIndex: 'time',
          key: 'time',
        },
        {
          title: 'Cost',
          dataIndex: 'cost',
          key: 'cost',
          render: (cost: number) => `$${cost}`,
        },
      ],
    },
    {
      title: 'Default setting',
      dataIndex: 'defaultSetting',
      key: 'defaultSetting',
    },
    {
      title: 'Next Hop',
      dataIndex: 'nextHop',
      key: 'nextHop',
    },
    {
      title: 'Ping',
      dataIndex: 'ping',
      key: 'ping',
      render: (status: "success" | "error" | "default" | "processing" | "warning" | undefined) => <Badge status={status} />,
    },
  ]

const data: NetworkMetric[] = [
    {
        key: '1',
        destinationPIP: '192.168.1.100',
        type: 'Direct',
        mask: '255.255.255.0',
        h5Status: 'success',
        w30Status: 'success',
        dgpStatus: 'success',
        t24Status: 'error',
        time: 150,
        cost: 25,
        defaultSetting: 'Unavailable',
        nextHop: '10.0.0.1',
        ping: 'success',
    },
    {
        key: '2',
        destinationPIP: '192.168.2.200',
        type: 'Indirect',
        mask: '255.255.255.0',
        h5Status: 'success',
        w30Status: 'success',
        dgpStatus: 'error',
        t24Status: 'error',
        time: 200,
        cost: 35,
        defaultSetting: 'Unavailable',
        nextHop: '10.0.0.2',
        ping: 'error',
    },
    {
        key: '3',
        destinationPIP: '192.168.3.150',
        type: 'Direct',
        mask: '255.255.255.0',
        h5Status: 'success',
        w30Status: 'success',
        dgpStatus: 'success',
        t24Status: 'success',
        time: 100,
        cost: 20,
        defaultSetting: 'Available',
        nextHop: '10.0.0.3',
        ping: 'success',
    },
    {
        key: '4',
        destinationPIP: '192.168.4.250',
        type: 'Indirect',
        mask: '255.255.255.0',
        h5Status: 'error',
        w30Status: 'error',
        dgpStatus: 'error',
        t24Status: 'error',
        time: 300,
        cost: 45,
        defaultSetting: 'Unavailable',
        nextHop: '10.0.0.4',
        ping: 'error',
    },
    {
        key: '5',
        destinationPIP: '192.168.5.50',
        type: 'Direct',
        mask: '255.255.255.0',
        h5Status: 'success',
        w30Status: 'success',
        dgpStatus: 'success',
        t24Status: 'success',
        time: 120,
        cost: 30,
        defaultSetting: 'Available',
        nextHop: '10.0.0.5',
        ping: 'success',
    },
    {
        key: '6',
        destinationPIP: '192.168.6.60',
        type: 'Indirect',
        mask: '255.255.255.0',
        h5Status: 'success',
        w30Status: 'success',
        dgpStatus: 'success',
        t24Status: 'success',
        time: 180,
        cost: 40,
        defaultSetting: 'Unavailable',
        nextHop: '10.0.0.6',
        ping: 'success',
    },
    {
        key: '7',
        destinationPIP: '192.168.7.70',
        type: 'Direct',
        mask: '255.255.255.0',
        h5Status: 'error',
        w30Status: 'error',
        dgpStatus: 'error',
        t24Status: 'error',
        time: 250,
        cost: 50,
        defaultSetting: 'Unavailable',
        nextHop: '10.0.0.7',
        ping: 'error',
    },
    {
        key: '8',
        destinationPIP: '192.168.8.80',
        type: 'Indirect',
        mask: '255.255.255.0',
        h5Status: 'success',
        w30Status: 'success',
        dgpStatus: 'success',
        t24Status: 'success',
        time: 140,
        cost: 35,
        defaultSetting: 'Available',
        nextHop: '10.0.0.8',
        ping: 'success',
    },
    {
        key: '9',
        destinationPIP: '192.168.9.90',
        type: 'Direct',
        mask: '255.255.255.0',
        h5Status: 'success',
        w30Status: 'success',
        dgpStatus: 'success',
        t24Status: 'success',
        time: 160,
        cost: 30,
        defaultSetting: 'Unavailable',
        nextHop: '10.0.0.9',
        ping: 'success',
    },
    {
        key: '10',
        destinationPIP: '192.168.10.10',
        type: 'Indirect',
        mask: '255.255.255.0',
        h5Status: 'error',
        w30Status: 'error',
        dgpStatus: 'error',
        t24Status: 'error',
        time: 220,
        cost: 40,
        defaultSetting: 'Unavailable',
        nextHop: '10.0.0.10',
        ping: 'error',
    },
    {
        key: '11',
        destinationPIP: '192.168.11.11',
        type: 'Direct',
        mask: '255.255.255.0',
        h5Status: 'success',
        w30Status: 'success',
        dgpStatus: 'success',
        t24Status: 'success',
        time: 130,
        cost: 25,
        defaultSetting: 'Available',
        nextHop: '10.0.0.11',
        ping: 'success',
    },
    {
        key: '12',
        destinationPIP: '192.168.12.12',
        type: 'Indirect',
        mask: '255.255.255.0',
        h5Status: 'success',
        w30Status: 'success',
        dgpStatus: 'success',
        t24Status: 'success',
        time: 190,
        cost: 35,
        defaultSetting: 'Unavailable',
        nextHop: '10.0.0.12',
        ping: 'success',
    },
    {
        key: '13',
        destinationPIP: '192.168.13.13',
        type: 'Direct',
        mask: '255.255.255.0',
        h5Status: 'error',
        w30Status: 'error',
        dgpStatus: 'error',
        t24Status: 'error',
        time: 240,
        cost: 45,
        defaultSetting: 'Unavailable',
        nextHop: '10.0.0.13',
        ping: 'error',
    },
    {
        key: '14',
        destinationPIP: '192.168.14.14',
        type: 'Indirect',
        mask: '255.255.255.0',
        h5Status: 'success',
        w30Status: 'success',
        dgpStatus: 'success',
        t24Status: 'success',
        time: 170,
        cost: 30,
        defaultSetting: 'Available',
        nextHop: '10.0.0.14',
        ping: 'success',
    },
    {
        key: '15',
        destinationPIP: '192.168.15.15',
        type: 'Direct',
        mask: '255.255.255.0',
        h5Status: 'success',
        w30Status: 'success',
        dgpStatus: 'success',
        t24Status: 'success',
        time: 180,
        cost: 35,
        defaultSetting: 'Unavailable',
        nextHop: '10.0.0.15',
        ping: 'success',
    },
]

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <Title level={2}>Rrouting Table</Title>
        <Tooltip title="Inbound routing decisions will be made based on these metrics">
          <QuestionCircleOutlined className="text-lg" />
        </Tooltip>
      </div>

      <Card>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{ pageSize: 10 }}
          scroll={{ x: true }}
          bordered
        />
      </Card>
    </div>
  )
}