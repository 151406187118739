'use client'

import { useState } from 'react'
import {
  Table,
  Button,
  Input,
  Form,
  Modal,
  Space,
  message,
  Typography,
  Layout,
  Card,
  Tooltip,
} from 'antd'
import {
  SearchOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons'
import type { TableProps } from 'antd'

const { Header, Content } = Layout
const { Title } = Typography

interface HostData {
  key: string
  name: string
  hostId: string
  location: string
  port: string
  status: 'online' | 'offline'
  telephone?: string
  address?: string
  pip?: string
}

export default function Component() {
  const [form] = Form.useForm()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [editingKey, setEditingKey] = useState('')
  const [searchText, setSearchText] = useState('')
  const [messageApi, contextHolder] = message.useMessage()

  // Sample data
  const [data, setData] = useState<HostData[]>([
    {
      key: '1',
      name: 'Host 1',
      hostId: 'H1',
      location: 'Location 1',
      port: '8080',
      status: 'online',
    },
    {
      key: '2',
      name: 'Host 2',
      hostId: 'H2',
      location: 'Location 2',
      port: '8080',
      status: 'offline',
    },
    {
      key: '3',
      name: 'Host 3',
      hostId: 'H3',
      location: 'Location 3',
      port: '8080',
      status: 'online',
    },
    {
      key: '4',
      name: 'Host 4',
      hostId: 'H4',
      location: 'Location 4',
      port: '8080',
      status: 'offline',
    },
    {
      key: '5',
      name: 'Host 5',
      hostId: 'H5',
      location: 'Location 5',
      port: '8080',
      status: 'online',
    },
    {
      key: '6',
      name: 'Host 6',
      hostId: 'H6',
      location: 'Location 6',
      port: '8080',
      status: 'offline',
    },
    {
      key: '7',
      name: 'Host 7',
      hostId: 'H7',
      location: 'Location 7',
      port: '8080',
      status: 'online',
    },
    {
      key: '8',
      name: 'Host 8',
      hostId: 'H8',
      location: 'Location 8',
      port: '8080',
      status: 'offline',
    },
    {
      key: '9',
      name: 'Host 9',
      hostId: 'H9',
      location: 'Location 9',
      port: '8080',
      status: 'online',
    },
    {
      key: '10',
      name: 'Host 10',
      hostId: 'H10',
      location: 'Location 10',
      port: '8080',
      status: 'offline',
    },
  ])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Host ID',
      dataIndex: 'hostId',
      key: 'hostId',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Port',
      dataIndex: 'port',
      key: 'port',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <div
          className={`w-3 h-3 rounded-full ${
            status === 'online' ? 'bg-green-500' : 'bg-red-500'
          }`}
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: HostData) => (
        <Space>
          <Button
            icon={<ThunderboltOutlined />}
            onClick={() => handlePing(record.key)}
          >
            Ping
          </Button>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          >
            Edit
          </Button>
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => showDeleteConfirm(record.key)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ]

  const handlePing = async (key: string) => {
    messageApi.loading('Pinging...', 1)
    // Simulate ping delay
    await new Promise(resolve => setTimeout(resolve, 1000))
    
    setData(prev =>
      prev.map(item =>
        item.key === key
          ? { ...item, status: item.status === 'online' ? 'offline' : 'online' }
          : item
      )
    )
    messageApi.success('Ping complete')
  }

  const handlePingAll = async () => {
    messageApi.loading('Pinging all hosts...', 1)
    await new Promise(resolve => setTimeout(resolve, 1000))
    setData(prev =>
      prev.map(item => ({
        ...item,
        status: Math.random() > 0.5 ? 'online' : 'offline',
      }))
    )
    messageApi.success('All hosts pinged')
  }

  const handleEdit = (record: HostData) => {
    form.setFieldsValue(record)
    setEditingKey(record.key)
    setIsModalVisible(true)
  }

  const handleAdd = () => {
    form.resetFields()
    setEditingKey('')
    setIsModalVisible(true)
  }

  const handleDelete = (key: string) => {
    setData(prev => prev.filter(item => item.key !== key))
    setIsDeleteModalVisible(false)
    messageApi.success('Host deleted')
  }

  const showDeleteConfirm = (key: string) => {
    setEditingKey(key)
    setIsDeleteModalVisible(true)
  }

  const onFinish = (values: any) => {
    if (editingKey) {
      setData(prev =>
        prev.map(item =>
          item.key === editingKey ? { ...values, key: editingKey } : item
        )
      )
      messageApi.success('Host updated')
    } else {
      const newKey = (Math.max(...data.map(item => Number(item.key))) + 1).toString()
      setData(prev => [...prev, { ...values, key: newKey }])
      messageApi.success('Host added')
    }
    setIsModalVisible(false)
  }

  const rowSelection: TableProps<HostData>['rowSelection'] = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys)
    },
  }

  return (
    <Layout className="min-h-screen">
      {contextHolder}

      <Content className="p-6">
        <Card>
          <div className="mb-4 flex justify-between items-center">
            <Space>
                <Input
                    placeholder="Search name"
                    prefix={<SearchOutlined />}
                    onChange={e => setSearchText(e.target.value)}
                    style={{ width: 200 }}
                />
              <Button
                type="primary"
                icon={<ThunderboltOutlined />}
                onClick={handlePingAll}
              >
                Ping All
              </Button>
              <Button
                icon={<PlusOutlined />}
                onClick={handleAdd}
              >
                Add
              </Button>
              <Button
                danger
                icon={<DeleteOutlined />}
                disabled={selectedRowKeys.length === 0}
                onClick={() => setIsDeleteModalVisible(true)}
              >
                Delete
              </Button>
            </Space>
          </div>

          <Table
            style={{ marginTop: '1rem' }}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            pagination={{
              total: data.length,
              pageSize: 10,
              showSizeChanger: true,
              showQuickJumper: true,
            }}
          />
        </Card>

        <Modal
          title={editingKey ? 'Edit Host' : 'Add Host'}
          open={isModalVisible}
          onOk={form.submit}
          onCancel={() => setIsModalVisible(false)}
          width={800}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
          >
            <div className="grid grid-cols-2 gap-4">
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="telephone"
                label="Telephone Number"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="location"
                label="Location"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="port"
                label="Port"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="address"
                label="Address"
                className="col-span-2"
              >
                <Input.TextArea
                  onChange={e => {
                    // Simulate PIP auto-generation
                    form.setFieldsValue({
                      pip: e.target.value ? `PIP-${Math.random().toString(36).substr(2, 9)}` : '',
                    })
                  }}
                />
              </Form.Item>
              <Form.Item
                name="pip"
                label="PIP"
                className="col-span-2"
              >
                <Input disabled />
              </Form.Item>
            </div>
          </Form>
        </Modal>

        <Modal
          title="Delete Confirmation"
          open={isDeleteModalVisible}
          onOk={() => handleDelete(editingKey)}
          onCancel={() => setIsDeleteModalVisible(false)}
        >
          <p>Choose OK to confirm the delete or CANCEL to back out of the del</p>
        </Modal>
      </Content>
    </Layout>
  )
}