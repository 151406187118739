'use client'

import { useState } from 'react'
import { 
  Button, 
  Card, 
  Form, 
  Input, 
  Modal, 
  Select, 
  Space, 
  Table, 
  Typography,
  message 
} from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'

const { Title } = Typography
const { Option } = Select

interface PSUType {
  key: string
  psuType: string
  transportationMode: string
  timeWindowStart: string
  timeWindowEnd: string
  capacity: string
  length: string
  width: string
  height: string
}

export default function Component() {
  const [form] = Form.useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalMode, setModalMode] = useState<'add' | 'edit'>('add')
  const [selectedPSU, setSelectedPSU] = useState<PSUType | null>(null)
  const [psuTypes, setPSUTypes] = useState<PSUType[]>([
    {
      key: '1',
      psuType: '3 axled trailer',
      transportationMode: 'Sea',
      timeWindowStart: '2024-01-06T12:00',
      timeWindowEnd: '2024-02-06T12:00',
      capacity: '40t',
      length: '13m',
      width: '2.5m',
      height: '4.0m',
    },
    {
      key: '2',
      psuType: '2 axled trailer',
      transportationMode: 'Road',
      timeWindowStart: '2024-01-07T09:00',
      timeWindowEnd: '2024-02-07T09:00',
      capacity: '30t',
      length: '12m',
      width: '2.4m',
      height: '3.8m',
    },
  ])

  const columns = [
    {
      title: 'PSU Type',
      dataIndex: 'psuType',
      key: 'psuType',
    },
    {
      title: 'Transportation Mode',
      dataIndex: 'transportationMode',
      key: 'transportationMode',
    },
    {
      title: 'Time Window',
      key: 'timeWindow',
      render: (_: any, record: PSUType) => (
        `${record.timeWindowStart.split('T')[1]} - ${record.timeWindowEnd.split('T')[1]}`
      ),
    },
    {
      title: 'Capacity',
      dataIndex: 'capacity',
      key: 'capacity',
    },
    {
      title: 'Length',
      dataIndex: 'length',
      key: 'length',
    },
    {
      title: 'Width',
      dataIndex: 'width',
      key: 'width',
    },
    {
      title: 'Height',
      dataIndex: 'height',
      key: 'height',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: PSUType) => (
        <Space size="middle">
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          >
            Edit
          </Button>
          <Button
            type="link"
            danger
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record.key)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ]

  const handleAdd = () => {
    setModalMode('add')
    setSelectedPSU(null)
    form.resetFields()
    setIsModalVisible(true)
  }

  const handleEdit = (record: PSUType) => {
    setModalMode('edit')
    setSelectedPSU(record)
    form.setFieldsValue(record)
    setIsModalVisible(true)
  }

  const handleDelete = (key: string) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this PSU type?',
      content: 'This action cannot be undone.',
      onOk() {
        setPSUTypes(psuTypes.filter(psu => psu.key !== key))
        message.success('PSU type deleted successfully')
      },
    })
  }

  const handleSubmit = (values: any) => {
    if (modalMode === 'add') {
      const newPSU: PSUType = {
        ...values,
        key: Date.now().toString(),
      }
      setPSUTypes([...psuTypes, newPSU])
      message.success('PSU type added successfully')
    } else {
      setPSUTypes(psuTypes.map(psu => 
        psu.key === selectedPSU?.key ? { ...psu, ...values } : psu
      ))
      message.success('PSU type updated successfully')
    }
    setIsModalVisible(false)
    form.resetFields()
  }

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <Title level={2}>PSU Type Management</Title>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleAdd}
        >
          Add PSU Type
        </Button>
      </div>

      <Card>
        <Table
          columns={columns}
          dataSource={psuTypes}
          pagination={false}
          scroll={{ x: true }}
        />
      </Card>

      <Modal
        title={modalMode === 'add' ? 'Add PSU Type' : 'Edit PSU Type'}
        open={isModalVisible}
        onOk={form.submit}
        onCancel={() => setIsModalVisible(false)}
        width={800}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Form.Item 
              label="PSU Type" 
              name="psuType" 
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item 
              label="Transportation Mode" 
              name="transportationMode" 
              rules={[{ required: true }]}
            >
              <Select>
                <Option value="Sea">Sea</Option>
                <Option value="Road">Road</Option>
                <Option value="Rail">Rail</Option>
                <Option value="Air">Air</Option>
              </Select>
            </Form.Item>

            <Form.Item 
              label="Time Window Start" 
              name="timeWindowStart" 
              rules={[{ required: true }]}
            >
              <Input type="datetime-local" />
            </Form.Item>

            <Form.Item 
              label="Time Window End" 
              name="timeWindowEnd" 
              rules={[{ required: true }]}
            >
              <Input type="datetime-local" />
            </Form.Item>

            <Form.Item 
              label="Capacity" 
              name="capacity" 
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item 
              label="Length" 
              name="length" 
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item 
              label="Width" 
              name="width" 
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item 
              label="Height" 
              name="height" 
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  )
}