'use client'

import { Menu } from 'antd'
import type { MenuProps } from 'antd'
import { Link } from 'react-router-dom'

export default function Component() {
  const items: MenuProps['items'] = [
    {
      key: 'main',
      label: <Link to="/" style={{ textAlign: 'left' }}>首页</Link>,
    },
    {
      key: 'login',
      label: <Link to="/login" style={{ textAlign: 'left' }}>登录</Link>,
    },
    {
      key: 'sending-host',
      label: <Link to="/hostDash" style={{ textAlign: 'left' }}>Sending Host</Link>,
      children: [
        {
          key: 'cpi-host-management',
          label: <Link to="/hostMgt" style={{ textAlign: 'left' }}>CPI Host Management</Link>,
        },
        {
          key: 'customer-order-management',
          label: <Link to="/customerOrder" style={{ textAlign: 'left' }}>Customer Order Management</Link>,
        },
        {
          key: 'warehouse-management',
          label: <Link to="/hostWarehouse" style={{ textAlign: 'left' }}>Warehouse Management</Link>,
        },
        {
          key: 'bandwidth',
          label: <Link to="/bandWidth" style={{ textAlign: 'left' }}>Bandwidth</Link>
        },
        {
          key: 'routing-table',
          label: <Link to="/routing-table" style={{ textAlign: 'left' }}>Routing Table</Link>,
        },
      ],
    },
    {
      key: 'router-manager-psu',
      label: <Link to="/router-manager" style={{ textAlign: 'left' }}>Router Manager</Link>,
      children: [
        {
          key: 'buffer-status',
          label: <Link to="/buffer-status" style={{ textAlign: 'left' }}>Buffer Status</Link>,
        },
        {
          key: 'bandwidth-psu',
          label: <Link to="/bandwidth" style={{ textAlign: 'left' }}>Bandwidth</Link>,
        },
        {
          key: 'routing-table-psu',
          label: <Link to="/routing-table" style={{ textAlign: 'left' }}>Routing Table</Link>,
        },
        {
          key: 'network-configuration-psu',
          label: <Link to="/network-config" style={{ textAlign: 'left' }}>Network Configuration</Link>,
        },
        {
          key: 'logistic-state-advertisement',
          label: <Link to="/logistics" style={{ textAlign: 'left' }}>Logistic State Advertisement</Link>,
        },
      ],
    },
    {
      key: 'receiving-host',
      label: <span style={{ textAlign: 'left' }}>Receiving Host</span>,
      children: [
        {
          key: 'cpi-host-management-receiving',
          label: <span style={{ textAlign: 'left' }}>CPI Host Management</span>,
        },
        {
          key: 'customer-order-management-receiving',
          label: <span style={{ textAlign: 'left' }}>Customer Order Management</span>,
        },
        {
          key: 'warehouse-management-receiving',
          label: <span style={{ textAlign: 'left' }}>Warehouse Management</span>,
        },
      ],
    },
    {
      key: 'carrier',
      label: <Link to="/carrier-dashboard" style={{ textAlign: 'left' }}>Carrier</Link>,
      children: [
        {
          key: 'traction-table',
          label: <Link to="/transaction-table" style={{ textAlign: 'left' }}>Traction Table</Link>,
        },
        {
          key: 'shipment-resource-management',
          label: <span style={{ textAlign: 'left' }}>Shipment Resource Management</span>,
        },
        {
          key: 'received-shipment-order',
          label: <span style={{ textAlign: 'left' }}>Received Shipment Order</span>,
        },
      ],
    },
    {
      key: 'cpi-service-provider',
      label: <Link to="/service-dahboard" style={{ textAlign: 'left' }}>CPI Service Provider</Link>,
      children: [
        {
          key: 'transport-layer',
          label: <Link to="/transport-layer" style={{ textAlign: 'left' }}>Transport Layer</Link>,
          children: [
            {
              key: 'psu-type-management',
              label: <Link to="/psu-type" style={{ textAlign: 'left' }}>PSU Type Management</Link>,
            },
            {
              key: 'connection',
              label: <Link to="/connection" style={{ textAlign: 'left' }}>Connection</Link>,
            },
            {
              key: 'order',
              label: <span style={{ textAlign: 'left' }}>Order</span>,
            },
            {
              key: 'shipment-order-management',
              label: <span style={{ textAlign: 'left' }}>Shipment Order Management</span>,
            },
          ],
        },
        {
          key: 'network-layer',
          label: <span style={{ textAlign: 'left' }}>Network Layer</span>,
          children: [
            {
              key: 'pip-address-configuration',
              label: <span style={{ textAlign: 'left' }}>PIP Address Configuration</span>,
            },
            {
              key: 'tractor-type-management',
              label: <span style={{ textAlign: 'left' }}>Tractor Type Management</span>,
            },
            {
              key: 'logistic-state-advertisement-network',
              label: <span style={{ textAlign: 'left' }}>Logistic State Advertisement</span>,
            },
          ],
        },
        {
          key: 'link-layer',
          label: <span style={{ textAlign: 'left' }}>Link Layer</span>,
          children: [
            {
              key: 'arp-table',
              label: <span style={{ textAlign: 'left' }}>ARP Table</span>,
            },
            {
              key: 'loi-table',
              label: <span style={{ textAlign: 'left' }}>LOI Table</span>,
            },
          ],
        },
        {
          key: 'twin-layer',
          label: <span style={{ textAlign: 'left' }}>Twin Layer</span>,
        },
        {
          key: 'network-configuration-provider',
          label: <Link to="/network-config" style={{ textAlign: 'left' }}>Network Configuration</Link>,
        },
      ],
    },
  ]

  return (
    <div className="w-64 min-h-screen bg-white border-r">
      <Menu
        mode="inline"
        theme='dark'
        defaultSelectedKeys={['login']}
        defaultOpenKeys={['sending-host']}
        items={items}
        className="h-full"
      />
    </div>
  )
}