'use client'

import { Card, Typography } from 'antd'
import { 
  Table2,
  Truck,
  ClipboardCheck
} from 'lucide-react'
import {Link} from 'react-router-dom'

const { Title } = Typography

export default function Component() {
  const menuItems = [
    {
      icon: <Table2 className="h-16 w-16" />,
      title: 'Traction Table',
      description: 'View and manage transportation schedules and tracking',
      href: '/traction-table',
      className: 'col-span-full'
    },
    {
      icon: <Truck className="h-16 w-16" />,
      title: 'Shipment Resource Management',
      description: 'Manage fleet resources and logistics',
      href: '/shipment-management',
      className: 'md:col-span-1'
    },
    {
      icon: <ClipboardCheck className="h-16 w-16" />,
      title: 'Received Shipment Order',
      description: 'Process and track incoming shipment orders',
      href: '/shipment-orders',
      className: 'md:col-span-1'
    }
  ]

  return (
    <div className="p-6">
      <div className="text-center mb-8">
        <Title level={2}>Carrier Dashboard</Title>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {menuItems.map((item, index) => (
          <Link 
            key={index} 
            to={item.href}
            className={`block no-underline ${item.className}`}
          >
            <Card 
              hoverable 
              className="h-full transition-all duration-300 hover:shadow-lg"
            >
              <div className="flex flex-col items-center text-center gap-4">
                <div className="text-primary">
                  {item.icon}
                </div>
                <div>
                  <Title level={3} className="mb-2">
                    {item.title}
                  </Title>
                  <p className="text-muted-foreground text-lg">
                    {item.description}
                  </p>
                </div>
              </div>
            </Card>
          </Link>
        ))}
      </div>

      {/* Stats Summary */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
        <Card>
          <div className="text-center">
            <div className="text-lg font-semibold text-muted-foreground">Active Shipments</div>
            <div className="text-3xl font-bold text-primary mt-2">24</div>
          </div>
        </Card>
        <Card>
          <div className="text-center">
            <div className="text-lg font-semibold text-muted-foreground">Available Resources</div>
            <div className="text-3xl font-bold text-primary mt-2">15</div>
          </div>
        </Card>
        <Card>
          <div className="text-center">
            <div className="text-lg font-semibold text-muted-foreground">Pending Orders</div>
            <div className="text-3xl font-bold text-primary mt-2">8</div>
          </div>
        </Card>
      </div>
    </div>
  )
}