'use client'

import { Button, Card, Col, Row, Table, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useState } from 'react'

const { Title } = Typography

interface StorageItem {
  key: string
  name: string
  type: string
  location: string
  weight: string
  size: string
  shipmentCondition: string
}

export default function Component() {
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      key: 'weight',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: 'Shipment Condition',
      dataIndex: 'shipmentCondition',
      key: 'shipmentCondition',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: StorageItem) => (
        <span className="space-x-2">
          <Button type="link">Edit</Button>
          <Button type="link" danger>Delete</Button>
        </span>
      ),
    },
  ]

  const data: StorageItem[] = [
    {
      key: '1',
      name: '1',
      type: '',
      location: '',
      weight: '',
      size: '',
      shipmentCondition: 'Fragile',
    },
    {
      key: '2',
      name: '2',
      type: '',
      location: '',
      weight: '',
      size: '',
      shipmentCondition: 'Moisture-resistant',
    },
  ]

  const tagColumns = [
    {
      title: 'Tag ID',
      dataIndex: 'tagId',
      key: 'tagId',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      key: 'weight',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: 'Shipment Condition',
      dataIndex: 'shipmentCondition',
      key: 'shipmentCondition',
    },
  ]

  const tagData = [
    {
      key: '1',
      tagId: '1',
      name: '1',
      type: '',
      location: '',
      weight: '',
      size: '',
      shipmentCondition: '',
    },
  ]

  return (
    <div className="p-6">
      <Title level={2}>Dashboard</Title>
      
      <Row gutter={[16, 16]} className="mb-6">
        <Col span={6}>
          <Card>
            <div className="text-center">
              <div className="text-lg font-bold">Awaiting Storage</div>
              <div className="text-2xl">20</div>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <div className="text-center">
              <div className="text-lg font-bold">Storage Record</div>
              <div className="text-2xl">500</div>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <div className="text-center">
              <div className="text-lg font-bold">Module Stored</div>
              <div className="text-2xl">40</div>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <div className="text-center">
              <div className="text-lg font-bold">Space Free</div>
              <div className="text-2xl">50</div>
            </div>
          </Card>
        </Col>
      </Row>

      <div className="mb-4">
        <Button type="primary" icon={<PlusOutlined />}>
          Add
        </Button>
      </div>

      <Table
        className="mb-6"
        columns={columns}
        dataSource={data}
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys: React.Key[]) => {
            setSelectedRowKeys(newSelectedRowKeys as string[])
          }
        }}
      />

      <div className="mb-2">
        <Typography.Text type="secondary">
          RFID Tag Information - Unique identifier for each label
        </Typography.Text>
      </div>
      
      <Table
        columns={tagColumns}
        dataSource={tagData}
        pagination={false}
      />
    </div>
  )
}