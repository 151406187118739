'use client'

import { Card, Table, Typography } from 'antd'
import { useState } from 'react'

const { Title } = Typography

interface PSUData {
  key: string
  psuType: string
  transportationMode: string
  timeWindow: string
  capacity: string
  length: string
  width: string
  height: string
}

interface TractorData {
  key: string
  id: number
  type: string
  nextHop: string
  mode: string
  capacity: string
  price: number
  timeWindow: string
  priority: number
  psu: string
}

export default function Component() {
  const psuColumns = [
    {
      title: 'PSU Type',
      dataIndex: 'psuType',
      key: 'psuType',
    },
    {
      title: 'Transportation Mode',
      dataIndex: 'transportationMode',
      key: 'transportationMode',
    },
    {
      title: 'Time Window',
      dataIndex: 'timeWindow',
      key: 'timeWindow',
    },
    {
      title: 'Capacity',
      dataIndex: 'capacity',
      key: 'capacity',
    },
    {
      title: 'Length',
      dataIndex: 'length',
      key: 'length',
    },
    {
      title: 'Width',
      dataIndex: 'width',
      key: 'width',
    },
    {
      title: 'Height',
      dataIndex: 'height',
      key: 'height',
    },
  ]

  const tractorColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Next Hop',
      dataIndex: 'nextHop',
      key: 'nextHop',
    },
    {
      title: 'Mode',
      dataIndex: 'mode',
      key: 'mode',
    },
    {
      title: 'Capacity',
      dataIndex: 'capacity',
      key: 'capacity',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (price: number) => `$${price}`,
    },
    {
      title: 'Time Window',
      dataIndex: 'timeWindow',
      key: 'timeWindow',
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
    },
    {
      title: 'PSU',
      dataIndex: 'psu',
      key: 'psu',
    },
  ]

  const psuData: PSUData[] = [
    {
      key: '1',
      psuType: '3 axled trailer',
      transportationMode: 'Sea',
      timeWindow: '01/06 12:00 - 02/06 12:00',
      capacity: '40t',
      length: '13m',
      width: '2.5m',
      height: '4.0m',
    },
    {
      key: '2',
      psuType: '2 axled trailer',
      transportationMode: 'Road',
      timeWindow: '02/06 14:00 - 03/06 14:00',
      capacity: '30t',
      length: '12m',
      width: '2.4m',
      height: '3.8m',
    },
    {
      key: '3',
      psuType: 'Container',
      transportationMode: 'Rail',
      timeWindow: '03/06 08:00 - 04/06 08:00',
      capacity: '35t',
      length: '12.5m',
      width: '2.4m',
      height: '2.9m',
    },
    {
      key: '4',
      psuType: 'Flatbed trailer',
      transportationMode: 'Road',
      timeWindow: '04/06 10:00 - 05/06 10:00',
      capacity: '45t',
      length: '13.6m',
      width: '2.5m',
      height: '4.2m',
    },
    {
      key: '5',
      psuType: 'Tank container',
      transportationMode: 'Sea',
      timeWindow: '05/06 15:00 - 06/06 15:00',
      capacity: '28t',
      length: '6.1m',
      width: '2.4m',
      height: '2.6m',
    },
  ]

  const tractorData: TractorData[] = [
    {
      key: '1',
      id: 1,
      type: 'Heavy Duty',
      nextHop: 'Shanghai Port',
      mode: 'Sea',
      capacity: '45t',
      price: 2500,
      timeWindow: '03/06 13:00 - 04/06 10:00',
      priority: 1,
      psu: 'A, B',
    },
    {
      key: '2',
      id: 2,
      type: 'Medium Duty',
      nextHop: 'Beijing Hub',
      mode: 'Road',
      capacity: '35t',
      price: 1800,
      timeWindow: '04/06 09:00 - 05/06 15:00',
      priority: 2,
      psu: 'C',
    },
    {
      key: '3',
      id: 3,
      type: 'Light Duty',
      nextHop: 'Guangzhou Center',
      mode: 'Road',
      capacity: '25t',
      price: 1200,
      timeWindow: '05/06 11:00 - 06/06 18:00',
      priority: 3,
      psu: 'D',
    },
    {
      key: '4',
      id: 4,
      type: 'Heavy Duty',
      nextHop: 'Shenzhen Port',
      mode: 'Sea',
      capacity: '42t',
      price: 2300,
      timeWindow: '06/06 08:00 - 07/06 16:00',
      priority: 1,
      psu: 'E, F',
    },
    {
      key: '5',
      id: 5,
      type: 'Medium Duty',
      nextHop: 'Chengdu Hub',
      mode: 'Rail',
      capacity: '38t',
      price: 2000,
      timeWindow: '07/06 10:00 - 08/06 14:00',
      priority: 2,
      psu: 'G',
    },
    {
      key: '6',
      id: 6,
      type: 'Heavy Duty',
      nextHop: 'Tianjin Port',
      mode: 'Sea',
      capacity: '44t',
      price: 2400,
      timeWindow: '08/06 09:00 - 09/06 17:00',
      priority: 1,
      psu: 'H, I',
    },
    {
      key: '7',
      id: 7,
      type: 'Light Duty',
      nextHop: 'Wuhan Center',
      mode: 'Road',
      capacity: '28t',
      price: 1400,
      timeWindow: '09/06 11:00 - 10/06 15:00',
      priority: 3,
      psu: 'J',
    },
    {
      key: '8',
      id: 8,
      type: 'Medium Duty',
      nextHop: 'Xian Hub',
      mode: 'Rail',
      capacity: '36t',
      price: 1900,
      timeWindow: '10/06 08:00 - 11/06 16:00',
      priority: 2,
      psu: 'K',
    },
    {
      key: '9',
      id: 9,
      type: 'Heavy Duty',
      nextHop: 'Qingdao Port',
      mode: 'Sea',
      capacity: '43t',
      price: 2350,
      timeWindow: '11/06 10:00 - 12/06 18:00',
      priority: 1,
      psu: 'L, M',
    },
    {
      key: '10',
      id: 10,
      type: 'Medium Duty',
      nextHop: 'Hangzhou Center',
      mode: 'Road',
      capacity: '34t',
      price: 1750,
      timeWindow: '12/06 09:00 - 13/06 14:00',
      priority: 2,
      psu: 'N',
    },
  ]

  return (
    <div className="p-6">
      <Title level={2}>Bandwidth Management</Title>
      
      <Card title="Table 1 PSU" className="mb-6">
        <Table
          columns={psuColumns}
          dataSource={psuData}
          pagination={false}
          scroll={{ x: true }}
        />
      </Card>

      <Card title="Table 2 Tractor">
        <Table
          columns={tractorColumns}
          dataSource={tractorData}
          pagination={{ pageSize: 5 }}
          scroll={{ x: true }}
        />
      </Card>
    </div>
  )
}