'use client'

import { useState } from 'react'
import { Button, Card, Table, Typography, message } from 'antd'
import { ScanOutlined } from '@ant-design/icons'

const { Title } = Typography

interface TransactionRecord {
  key: string
  psuId: string
  date: string
  planStart: string
  planEnd: string
  actualStart: string | null
  actualEnd: string | null
  elapsedTime: number | null
}

export default function Component() {
  const [data, setData] = useState<TransactionRecord[]>([
    {
      key: '1',
      psuId: 'PSU001',
      date: '2024-01-01',
      planStart: '08:00',
      planEnd: '09:00',
      actualStart: '8:29',
      actualEnd: '8:43',
      elapsedTime: 840,
    },
    {
      key: '2',
      psuId: 'PSU002',
      date: '2024-01-01',
      planStart: '09:30',
      planEnd: '10:30',
      actualStart: null,
      actualEnd: null,
      elapsedTime: null,
    },
    {
      key: '3',
      psuId: 'PSU003',
      date: '2024-01-01',
      planStart: '11:00',
      planEnd: '12:00',
      actualStart: '11:15',
      actualEnd: null,
      elapsedTime: null,
    },
  ])

  const calculateElapsedTime = (start: string, end: string) => {
    const startParts = start.split(':').map(Number)
    const endParts = end.split(':').map(Number)
    const startSeconds = startParts[0] * 3600 + startParts[1] * 60
    const endSeconds = endParts[0] * 3600 + endParts[1] * 60
    return endSeconds - startSeconds
  }

  const simulateRFIDScan = (record: TransactionRecord) => {
    const currentTime = new Date()
    const timeString = `${currentTime.getHours()}:${currentTime.getMinutes()}`

    const updatedData = data.map(item => {
      if (item.key === record.key) {
        // If no actual start time, set it
        if (!item.actualStart) {
          message.success(`RFID scan recorded for start time: ${timeString}`)
          return {
            ...item,
            actualStart: timeString,
          }
        }
        // If has start time but no end time, set end time and calculate elapsed time
        else if (!item.actualEnd) {
          const elapsedTime = calculateElapsedTime(item.actualStart, timeString)
          message.success(`RFID scan recorded for end time: ${timeString}`)
          return {
            ...item,
            actualEnd: timeString,
            elapsedTime,
          }
        }
        return item
      }
      return item
    })

    setData(updatedData)
  }

  const columns = [
    {
      title: 'PSU ID',
      dataIndex: 'psuId',
      key: 'psuId',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Plan Start',
      dataIndex: 'planStart',
      key: 'planStart',
    },
    {
      title: 'Plan End',
      dataIndex: 'planEnd',
      key: 'planEnd',
    },
    {
      title: 'Actual Start',
      dataIndex: 'actualStart',
      key: 'actualStart',
    },
    {
      title: 'Actual End',
      dataIndex: 'actualEnd',
      key: 'actualEnd',
    },
    {
      title: 'Elapsed Time (s)',
      dataIndex: 'elapsedTime',
      key: 'elapsedTime',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: TransactionRecord) => (
        <Button
          icon={<ScanOutlined />}
          onClick={() => simulateRFIDScan(record)}
          disabled={!!record.actualStart && !!record.actualEnd}
        >
          RFID Scan
        </Button>
      ),
    },
  ]

  return (
    <div className="p-6">
      <Title level={2} className="mb-6">Traction Table</Title>
      
      <Card>
        <div className="mb-4 text-gray-500">
          Note: Actual Start and End times are determined by RFID scanning. 
          Elapsed Time is automatically calculated after scanning.
        </div>
        
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ x: true }}
        />
      </Card>
    </div>
  )
}