import React from 'react'
import { Image, Typography } from 'antd'

const { Title } = Typography

export default function Component() {
  return (
    <div className="flex items-center justify-center p-4 bg-gray-800">
      <div className="flex items-center gap-4">
        <Image
          width={40}
          src="/placeholder.svg?height=40&width=40"
          alt="Cyber Physical Internet Logo"
          preview={false}
          className="rounded"
        />
        <Title level={4} style={{ margin: 0, color: 'white' }}>
          Cyber Physical Internet
        </Title>
      </div>
    </div>
  )
}