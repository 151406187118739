'use client'

import { useState } from 'react'
import { 
  Badge,
  Button, 
  Card, 
  Checkbox, 
  Form, 
  Input, 
  Modal, 
  Space, 
  Table, 
  Tabs,
  Typography,
  message
} from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'

const { Title } = Typography
const { TabPane } = Tabs

interface DHCPScope {
  key: string
  scopeName: string
  status: 'Active' | 'Inactive'
  pip: string
  gateway: string
  dns: string
  pipPool: string
}

export default function Component() {
  const [vlanForm] = Form.useForm()
  const [dhcpForm] = Form.useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalMode, setModalMode] = useState<'add' | 'edit'>('add')
  const [selectedScope, setSelectedScope] = useState<DHCPScope | null>(null)
  const [dhcpScopes, setDhcpScopes] = useState<DHCPScope[]>([
    {
      key: '1',
      scopeName: 'Main Office',
      status: 'Active',
      pip: '192.168.1.0/24',
      gateway: '192.168.1.1',
      dns: '8.8.8.8',
      pipPool: '192.168.1.100-192.168.1.200',
    },
    {
      key: '2',
      scopeName: 'Guest Network',
      status: 'Inactive',
      pip: '192.168.2.0/24',
      gateway: '192.168.2.1',
      dns: '8.8.4.4',
      pipPool: '192.168.2.100-192.168.2.200',
    },
  ])

  const dhcpColumns = [
    {
      title: 'Scope Name',
      dataIndex: 'scopeName',
      key: 'scopeName',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <Badge status={status === 'Active' ? 'success' : 'default'} text={status} />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: DHCPScope) => (
        <Space size="middle">
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          >
            Edit
          </Button>
          <Button
            type="link"
            danger
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record.key)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ]

  const handleVLANSubmit = (values: any) => {
    console.log('VLAN Configuration:', values)
    message.success('VLAN configuration saved successfully')
  }

  const handleDHCPSubmit = (values: any) => {
    if (modalMode === 'add') {
      const newScope: DHCPScope = {
        ...values,
        key: Date.now().toString(),
        status: 'Inactive',
      }
      setDhcpScopes([...dhcpScopes, newScope])
      message.success('DHCP scope added successfully')
    } else {
      setDhcpScopes(dhcpScopes.map(scope => 
        scope.key === selectedScope?.key ? { ...scope, ...values } : scope
      ))
      message.success('DHCP scope updated successfully')
    }
    setIsModalVisible(false)
    dhcpForm.resetFields()
  }

  const handleEdit = (record: DHCPScope) => {
    setSelectedScope(record)
    setModalMode('edit')
    dhcpForm.setFieldsValue(record)
    setIsModalVisible(true)
  }

  const handleDelete = (key: string) => {
    setDhcpScopes(dhcpScopes.filter(scope => scope.key !== key))
    message.success('DHCP scope deleted successfully')
  }

  const handleAddScope = () => {
    setModalMode('add')
    setSelectedScope(null)
    dhcpForm.resetFields()
    setIsModalVisible(true)
  }

  return (
    <div className="p-6" style={{ margin: '0 20px' }}>
      <Title level={2} className="mb-6">Network Configuration</Title>

      <Tabs defaultActiveKey="vlan">
        <TabPane tab="VLAN Configuration" key="vlan">
          <Card title="VLAN Details" className="mb-6">
            <Form
              form={vlanForm}
              layout="vertical"
              onFinish={handleVLANSubmit}
              initialValues={{
                activateDHCP: false,
              }}
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <Form.Item label="VLAN ID" name="vlanId" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>

                <Form.Item label="PIP" name="pip" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>

                <Form.Item label="VLAN Location" name="vlanLocation" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>

                <Form.Item label="Subnet Mask" name="subnetMask" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>

                <Form.Item label="Gateway" name="gateway" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>

                <Form.Item name="activateDHCP" valuePropName="checked">
                  <Checkbox>Activate the DHCP Service</Checkbox>
                </Form.Item>

                <Form.Item label="PIP Pool Start" name="pipPoolStart" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>

                <Form.Item label="PIP Pool End" name="pipPoolEnd" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>

                <Form.Item label="PIP Reservation" name="pipReservation">
                  <Input />
                </Form.Item>

                <Form.Item label="DNS" name="dns" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>

                <Form.Item label="Lease Time (hours)" name="leaseTime" rules={[{ required: true }]}>
                  <Input type="number" />
                </Form.Item>
              </div>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save VLAN Configuration
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </TabPane>

        <TabPane tab="DHCP Service" key="dhcp">
          <Card className="mb-6">
            <div className="flex items-center mb-4">
              <Badge status="success" text="DHCP Service Status: Active" />
              <Button 
                type="primary" 
                icon={<PlusOutlined />} 
                onClick={handleAddScope}
                className="ml-auto"
              >
                Add Scope
              </Button>
            </div>

            <Table
              columns={dhcpColumns}
              dataSource={dhcpScopes}
              pagination={false}
            />
          </Card>
        </TabPane>
      </Tabs>

      {/* Add/Edit DHCP Scope Modal */}
      <Modal
        title={modalMode === 'add' ? "Add DHCP Scope" : "Edit DHCP Scope"}
        open={isModalVisible}
        onOk={dhcpForm.submit}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form
          form={dhcpForm}
          layout="vertical"
          onFinish={handleDHCPSubmit}
        >
          <Form.Item label="Scope Name" name="scopeName" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item label="PIP" name="pip" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Gateway" name="gateway" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item label="DNS" name="dns" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item label="PIP Pool" name="pipPool" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}