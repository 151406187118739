import React from 'react';
import logo from './logo.svg';
import './App.css';
import LoginControl from './login/login-control';
import MainPage from './home/home';
import {AuthenticationService} from './login/authentication/authentication-service';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link
} from 'react-router-dom';
import { Layout, Menu } from 'antd';
import MenuComponent  from './home/menu';
import HostBoard from './host/board';
import CPIHostManagement from './host/management';
import WarehouseManagement from './host/wahouse-management';
import CreateCustomerOrder from './host/create-customer-order';
import BandWidth from './host/bandwidth';
import RoutingTable from './host/routing-table';
import RouterManager from './router/dashboard';
import BufferStatus from './router/buffer-status';
import NetworkConfig from './router/network-configuration';
import TranscationTable from './carrier/transaction-table';
import CarrierDashboard from './carrier/dashboard';
import ServiceDahboard from './service/dashboard';
import PsuTypeManagement from './service/psu-type-management';
import Connection from './service/connection';
import TransportLayer from './service/transport-layer';

const { Header, Sider, Content } = Layout;

function App() {
  return (
    <div className="App">
      <Router>
        {/* {
          !AuthenticationService.isAuthenticated() ? (
            <LoginControl/>
          ): null
        } */}
        <Layout style={{ minHeight: '100vh' }}>
          <Sider collapsible width={256}>
            <MenuComponent />
          </Sider>
          <Content>
            <Routes>
              <Route path="/" element={<MainPage />} />
              <Route path="/login" element={<LoginControl />} />
              <Route path="/hostDash" element={<HostBoard />} />
              <Route path="/hostMgt" element={<CPIHostManagement />} />
              <Route path="/hostWarehouse" element={<WarehouseManagement />} />
              <Route path="/customerOrder" element={<CreateCustomerOrder />} />
              <Route path="/bandWidth" element={<BandWidth />} />
              <Route path="/routing-table" element={<RoutingTable />} />
              <Route path="/router-manager" element={<RouterManager />} />
              <Route path="/buffer-status" element={<BufferStatus />} />
              <Route path="/network-config" element={<NetworkConfig />} />
              <Route path="/transaction-table" element={<TranscationTable />} />
              <Route path="/carrier-dashboard" element={<CarrierDashboard />} />
              <Route path="/service-dahboard" element={<ServiceDahboard />} />
              <Route path="/transport-layer" element={<TransportLayer />} />
              <Route path="/psu-type" element={<PsuTypeManagement />} />
              <Route path="/connection" element={<Connection />} />
            </Routes>
          </Content>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
