'use client'

import { useState } from 'react'
import { Button, Card, Form, Input, Modal, Space, Table, Tag, Typography } from 'antd'
import { EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons'

const { Title } = Typography

interface PSURecord {
  key: string
  psuId: string
  operator: string
  address: string
  inboundOrderNumber: string
  inboundTime: string
  outboundTime: string
  status: 'Ship in Progress' | 'Arrived' | 'Stocked' | 'Pending Departure' | 'Dispatched'
}

export default function Component() {
  const [form] = Form.useForm()
  const [isAddModalVisible, setIsAddModalVisible] = useState(false)
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [isViewModalVisible, setIsViewModalVisible] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState<PSURecord | null>(null)

  const getStatusColor = (status: string) => {
    const colors: { [key: string]: string } = {
      'Ship in Progress': 'processing',
      'Arrived': 'success',
      'Stocked': 'cyan',
      'Pending Departure': 'warning',
      'Dispatched': 'default'
    }
    return colors[status] || 'default'
  }

  const columns = [
    {
      title: 'PSU ID',
      dataIndex: 'psuId',
      key: 'psuId',
    },
    {
      title: 'Operator',
      dataIndex: 'operator',
      key: 'operator',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Inbound Order Number',
      dataIndex: 'inboundOrderNumber',
      key: 'inboundOrderNumber',
    },
    {
      title: 'Inbound Time',
      dataIndex: 'inboundTime',
      key: 'inboundTime',
    },
    {
      title: 'Outbound Time',
      dataIndex: 'outboundTime',
      key: 'outboundTime',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <Tag color={getStatusColor(status)}>
          {status}
        </Tag>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: PSURecord) => (
        <Space size="middle">
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => {
              setSelectedRecord(record)
              form.setFieldsValue(record)
              setIsEditModalVisible(true)
            }}
          >
            Edit
          </Button>
          <Button
            type="link"
            icon={<EyeOutlined />}
            onClick={() => {
              setSelectedRecord(record)
              setIsViewModalVisible(true)
            }}
          >
            View
          </Button>
        </Space>
      ),
    },
  ]

  const data: PSURecord[] = [
    {
      key: '1',
      psuId: 'PSU001',
      operator: 'John Smith',
      address: '123 Logistics Way',
      inboundOrderNumber: 'IN001',
      inboundTime: '2024-01-01 09:00',
      outboundTime: '2024-01-02 14:00',
      status: 'Ship in Progress',
    },
    {
      key: '2',
      psuId: 'PSU002',
      operator: 'Jane Doe',
      address: '456 Warehouse Ave',
      inboundOrderNumber: 'IN002',
      inboundTime: '2024-01-02 10:30',
      outboundTime: '2024-01-03 15:30',
      status: 'Arrived',
    },
    {
      key: '3',
      psuId: 'PSU003',
      operator: 'Mike Johnson',
      address: '789 Storage Blvd',
      inboundOrderNumber: 'IN003',
      inboundTime: '2024-01-03 11:15',
      outboundTime: '2024-01-04 16:15',
      status: 'Stocked',
    },
    {
      key: '4',
      psuId: 'PSU004',
      operator: 'Sarah Wilson',
      address: '321 Transport St',
      inboundOrderNumber: 'IN004',
      inboundTime: '2024-01-04 13:45',
      outboundTime: '2024-01-05 18:45',
      status: 'Pending Departure',
    },
    {
      key: '5',
      psuId: 'PSU005',
      operator: 'David Brown',
      address: '654 Shipping Lane',
      inboundOrderNumber: 'IN005',
      inboundTime: '2024-01-05 14:20',
      outboundTime: '2024-01-06 19:20',
      status: 'Dispatched',
    },
  ]

  const handleAdd = (values: any) => {
    console.log('Add new record:', values)
    setIsAddModalVisible(false)
    form.resetFields()
  }

  const handleEdit = (values: any) => {
    console.log('Edit record:', values)
    setIsEditModalVisible(false)
    form.resetFields()
  }

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <Title level={2}>Buffer Status</Title>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            form.resetFields()
            setIsAddModalVisible(true)
          }}
        >
          Add PSU
        </Button>
      </div>

      <Card>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{ pageSize: 5 }}
          scroll={{ x: true }}
        />
      </Card>

      {/* Add Modal */}
      <Modal
        title="Add PSU Record"
        open={isAddModalVisible}
        onOk={form.submit}
        onCancel={() => setIsAddModalVisible(false)}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleAdd}
        >
          <Form.Item label="PSU ID" name="psuId" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Operator" name="operator" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Address" name="address" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Inbound Order Number" name="inboundOrderNumber" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Inbound Time" name="inboundTime" rules={[{ required: true }]}>
            <Input type="datetime-local" />
          </Form.Item>
          <Form.Item label="Outbound Time" name="outboundTime" rules={[{ required: true }]}>
            <Input type="datetime-local" />
          </Form.Item>
        </Form>
      </Modal>

      {/* Edit Modal */}
      <Modal
        title="Edit PSU Record"
        open={isEditModalVisible}
        onOk={form.submit}
        onCancel={() => setIsEditModalVisible(false)}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleEdit}
        >
          <Form.Item label="PSU ID" name="psuId" rules={[{ required: true }]}>
            <Input disabled />
          </Form.Item>
          <Form.Item label="Operator" name="operator" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Address" name="address" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Inbound Order Number" name="inboundOrderNumber" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Inbound Time" name="inboundTime" rules={[{ required: true }]}>
            <Input type="datetime-local" />
          </Form.Item>
          <Form.Item label="Outbound Time" name="outboundTime" rules={[{ required: true }]}>
            <Input type="datetime-local" />
          </Form.Item>
        </Form>
      </Modal>

      {/* View Modal */}
      <Modal
        title="View PSU Record"
        open={isViewModalVisible}
        footer={null}
        onCancel={() => setIsViewModalVisible(false)}
      >
        {selectedRecord && (
          <div className="space-y-4">
            <div>
              <div className="font-semibold">PSU ID</div>
              <div>{selectedRecord.psuId}</div>
            </div>
            <div>
              <div className="font-semibold">Operator</div>
              <div>{selectedRecord.operator}</div>
            </div>
            <div>
              <div className="font-semibold">Address</div>
              <div>{selectedRecord.address}</div>
            </div>
            <div>
              <div className="font-semibold">Inbound Order Number</div>
              <div>{selectedRecord.inboundOrderNumber}</div>
            </div>
            <div>
              <div className="font-semibold">Inbound Time</div>
              <div>{selectedRecord.inboundTime}</div>
            </div>
            <div>
              <div className="font-semibold">Outbound Time</div>
              <div>{selectedRecord.outboundTime}</div>
            </div>
            <div>
              <div className="font-semibold">Status</div>
              <Tag color={getStatusColor(selectedRecord.status)}>
                {selectedRecord.status}
              </Tag>
            </div>
          </div>
        )}
      </Modal>
    </div>
  )
}